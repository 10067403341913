<style lang="less" scoped>
  @import "../../../assets/css/variables";
  #main {
    width: 100%;
    height: 400px;
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>故障统计年度报表</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="page-container">
      <el-form :inline="true">
        <el-form-item>
          <el-select
            v-if="isAdmin"
            v-model="deviceType"
            placeholder="请选择产品型号"
          >
            <el-option
              v-for="item in dvcTypeList"
              :key="item.id"
              :label="item.deviceTypeCode + item.productTypeCode"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select v-else v-model="deviceType" placeholder="请选择产品型号">
            <el-option
              v-for="item in brandTypeList"
              :key="item.id"
              :label="item.deviceTypeCode + item.productTypeCode"
              :value="item.deviceType"
            ></el-option>
          </el-select>
        </el-form-item>
        <!--     <el-form-item>
          <el-select v-model="year"
                     placeholder="请选择年份"
                     @change="getMonth">
            <el-option
                v-for="(item, index) in yearList" :key="index"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="getData">
            查询
          </el-button>
        </el-form-item>
      </el-form>

      <div id="main"></div>

      <el-table
        show-summary
        :data="tableData"
        class="list-table"
        style="width: 80%;margin:15px 10%"
      >
        <el-table-column
          :show-overflow-tooltip="true"
          prop="name"
          align="center"
          label="故障信息"
        ></el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          align="center"
          prop="value"
          label="故障数量"
        ></el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import {
    getProductAuthoData,
    getShopData,
    getAlertCountDatas,
    getOnlineData,
  } from "../../../services/report";
  import { mapGetters } from "vuex";
  import {
    searchDevicesByType,
    searchProByCustomer,
  } from "../../../services/device";
  import { PAGE_SIZE } from "../../../config";
  import { queryBrander } from "../../../services/customer";
  import echarts from "echarts";
  export default {
    data() {
      return {
        numData: {},
        isAdmin: false,
        testData: [],
        allCustomer: null,
        customer: null,
        customerProxy: null,
        brandList: [],
        brandTypeList: [],
        type: "",
        deviceType: null,
        dvcTypeList: [],
        year: "",
        yearList: [],
        tableData: [],
        tableData: [],
        month: 12,
        monthShow: 12,
        PAGE_SIZE,
        pager: {
          size: 999,
          page: 1,
        },
        total: 0,
      };
    },
    mounted() {
      this.getYear();
      this.checkRole();
      this.getData();
    },
    computed: {
      ...mapGetters({ user: "userInfo" }),
    },
    methods: {
      //角色判断
      checkRole() {
        const role = this.user.role;
        if (/admin/.test(role)) {
          this.isAdmin = true;
          this.allCustomer = "all";
          this.queryDvctypes("");
        } else {
          this.customer = this.user.customerId;
          this.queryBrandTypeList();
        }
      },
      // 初始化年份
      getYear() {
        const date = new Date();
        const year = date.getFullYear();
        this.yearList = [year, year - 1];
        this.year = year;
      },

      //初始化月份
      getMonth() {},

      /**
       * 获取所有品牌商
       */
      async queryBrander(key) {
        const res = await queryBrander({ isProxy: true });
        if (res && res.errorCode === 0) {
          this.brandList = (res && res.data) || [];
        }
        //        this.loaded = true;
      },
      /**
       * 获取品牌型号列表
       */
      async queryBrandTypeList() {
        if (this.customer) {
          const customer = this.customer;
          const responseData = await searchProByCustomer(
            { customer },
            { size: 9999, page: 1 }
          );
          if (responseData.errorCode === 0) {
            this.brandTypeList =
              (responseData && responseData.data && responseData.data.datas) ||
              [];
          }
        }
      },
      /**
       * 获取设备类型列表
       */
      async queryDvctypes(key) {
        const responseData = await searchDevicesByType(
          { key: key },
          this.pager
        );
        if (responseData.errorCode === 0) {
          this.dvcTypeList =
            (responseData && responseData.data && responseData.data.datas) ||
            [];
        }
        //        this.loaded = true;
      },

      brandChange() {
        if (this.customer === "") {
          this.allCustomer = "all";
          this.customer = null;
        } else {
          this.allCustomer = null;
          this.deviceType = null;
          this.queryBrandTypeList();
        }
      },

      //产品型号授权数量报表
      async getData() {
        this.numData = {};
        //        this.dstaData = [];
        //        this.staData = [];
        //        this.tableData = [];
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        if (this.year === year) {
          this.month = month;
        } else {
          this.month = 12;
        }
        this.monthShow = this.month;
        let params = {
          year: this.year,
          allCustomer: this.allCustomer,
          customer: this.customer,
          customerProxy: this.customerProxy,
          deviceType: this.deviceType,
        };
        const res = await getAlertCountDatas(params, this.pager);
        if (res && res.errorCode === 0 && res.data) {
          this.tableData = res.data;
          if (res.data.length < 1) {
            this.numData = {};
          } else {
            this.numData.children = res.data;
          }

          this.myChart = echarts.init(document.getElementById("main"));
          let self = this;
          this.myChart.on("click", function(params) {
            if (params.data && params.data.children !== null) {
              self.testData[0] = res.data;
              if (params.treePathInfo.length === 2) {
                self.testData[1] = params.data.children;
              } else if (params.treePathInfo.length === 3) {
                self.testData[2] = params.data.children;
              } else if (params.treePathInfo.length === 4) {
                self.testData[3] = params.data.children;
              }
              self.tableData = params.data.children;
            } else {
              if (params.treePathInfo.length === 1) {
                self.tableData = self.testData[0] || [];
              } else if (params.treePathInfo.length === 2) {
                self.tableData = self.testData[1] || [];
              } else if (params.treePathInfo.length === 3) {
                self.tableData = self.testData[2] || [];
              } else if (params.treePathInfo.length === 4) {
                self.tableData = self.testData[3] || [];
              }
            }
            //            console.log(self.tableData);
            //            console.log(params);
          });

          this.myChart.setOption(
            {
              tooltip: {},
              series: [
                {
                  name: res.data.length < 1 ? "无故障数据" : "所有故障",
                  type: "treemap",
                  visibleMin: 300,
                  roam: false,
                  data: this.numData.children,
                  leafDepth: 1,
                  levels: [
                    {
                      itemStyle: {
                        normal: {
                          borderColor: "#555",
                          borderWidth: 4,
                          gapWidth: 4,
                        },
                      },
                    },
                    {
                      colorSaturation: [0.3, 0.6],
                      itemStyle: {
                        normal: {
                          borderColorSaturation: 0.7,
                          gapWidth: 2,
                          borderWidth: 2,
                        },
                      },
                    },
                    {
                      colorSaturation: [0.3, 0.5],
                      itemStyle: {
                        normal: {
                          borderColorSaturation: 0.6,
                          gapWidth: 1,
                        },
                      },
                    },
                    {
                      colorSaturation: [0.3, 0.5],
                    },
                  ],
                },
              ],
            },
            true
          );
        } else {
          this.tableData = [];
        }
      },
      /**
       * 翻页
       */
      handleCurrentChange(page) {
        // if (this.loaded) {
        this.pager.page = page;
        //window.router.replace({path: 'list', query: {page: page}})
        this.getData();
        // }
      },
      /**
       * 设置分页的size
       */
      handleSizeChange(val) {
        this.pager.size = val;
        this.pager.page = 1;
        this.getData();
      },
    },
    watch: {},
  };
</script>
